<template>
  <div>
    <feedback-table />
    <Emails />
  </div>
</template>

<script>
import FeedbackTable from "@/components/Feedback/FeedbackTable";
import Emails from "@/components/Feedback/Emails";

export default {
  name: "Feedback",
  components: { FeedbackTable, Emails },
};
</script>

<style scoped></style>
