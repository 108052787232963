var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-tabs',_vm._l((_vm.statuses),function(status){return _c('v-tab',{key:status.name,on:{"click":function($event){return _vm.changeTab(status.value)}}},[_vm._v(_vm._s(status.name))])}),1),_c('v-row',{staticClass:"px-5 pt-4 pb-3",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"5"}},[_c('v-text-field',{attrs:{"label":"Поиск имя, email","value":_vm.optionsTable.search,"hide-details":"","clearable":""},on:{"change":function (value) { return (_vm.optionsTable.search = value); },"click:clear":function($event){_vm.optionsTable.search = ''}}})],1),_c('v-col',{attrs:{"cols":"auto","align-self":"end"}},[_c('v-btn',{attrs:{"color":"accent","elevation":"0","width":"40"}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.optionsTable,"server-items-length":_vm.total,"loading":_vm.loading,"show-expand":"","footer-props":{ itemsPerPageOptions: [10, 15, 20, 50, 100] }},on:{"update:options":function($event){_vm.optionsTable=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-inline-block"},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"ripple":false,"hide-details":""},on:{"change":function($event){return _vm.changeStatus(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',[_c('v-col',{staticClass:"py-10 px-3 px-lg-5",attrs:{"lg":"7"}},[_vm._v(" "+_vm._s(item.text)+" ")])],1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[[_c('v-card',{staticClass:"pa-1"},[_c('v-card-title',{staticClass:"text-h5 text-center justify-center flex-column mb-5"},[_vm._v(" Удалить сообщение"),_c('br'),_c('span',{staticClass:"accent--text"},[_vm._v(_vm._s(_vm.deleteFeedback.email))])]),_c('v-card-actions',{staticClass:"pb-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"accent","outlined":""},on:{"click":_vm.closeDelete}},[_vm._v("Отмена")]),_c('v-btn',{attrs:{"color":"accent"},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }