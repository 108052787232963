<template>
  <div>
    <div class="text-h4 pt-10 mb-5">Рассылка</div>
    <v-row v-if="emails.length" class="mb-5">
      <v-col md="5" lg="4">
        <v-row v-for="email in emails" :key="email.id">
          <v-col cols="10 body-1 py-1">
            <div>{{ email.email }}</div>
          </v-col>
          <v-col cols="2" class="text-center py-0">
            <v-btn
              icon
              @click="
                dialogDelete = true;
                deleteId = email.id;
              "
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-divider />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div v-else class="mb-5">Список пуст</div>
    <v-row class="mb-4">
      <v-col cols="12" md="5" lg="4">
        <v-text-field
          ref="formData.ip"
          v-model="formData.email"
          :rules="[rules.required]"
          :error="errors.email ? true : false"
          :error-messages="errors.email"
          @focus="errors.email = null"
          label="Email"
          outlined
          dense
        ></v-text-field>
        <v-btn color="accent" :loading="pending" @click="addEmail">
          добавить
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <template>
        <v-card class="pa-1">
          <v-card-title
            class="text-h5 text-center justify-center flex-column mb-5"
          >
            Удалить <br />
            <i class="accent--text">{{ deleteEmailText }}</i>
            из списка ?
          </v-card-title>
          <v-card-actions class="pb-4">
            <v-spacer></v-spacer>
            <v-btn color="accent" outlined @click="closeDelete">Отмена</v-btn>
            <v-btn color="accent" @click="deleteConfirm">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import api from "../../../api";
import rules from "@/mixins/rules";

export default {
  name: "BlockingCountries",
  mixins: [rules],
  data() {
    return {
      dialogDelete: false,
      deleteId: null,
      pending: false,
      emails: [],
      errors: {},
      formData: {
        email: "",
      },
      defaultFormData: {
        email: "",
      },
    };
  },
  computed: {
    deleteEmailText() {
      return this.deleteId
        ? this.emails.find((email) => email.id === this.deleteId).email
        : "";
    },
  },
  mounted() {
    this.getEmailsList();
  },
  methods: {
    async getEmailsList() {
      try {
        const res = await api.feedbacks.emails();
        this.emails = res.data;
      } catch (e) {
        console.error(e);
      }
    },
    async addEmail() {
      let formHasErrors = this.validationObject(this.formData, "formData");
      if (formHasErrors) {
        this.$store.dispatch("showSnackbar", {
          msg: "Заполните обязательные поля",
          color: "error",
          show: true,
        });
        return;
      }
      try {
        this.pending = true;
        await api.feedbacks.createEmails(this.formData);
        this.getEmailsList();
        this.pending = false;
        this.formData = { ...this.defaultFormData };
        this.resetErrors(this.formData, "formData");
      } catch (e) {
        console.error(e);
        if (e?.response?.data?.errors) {
          this.errors = { ...e.response.data.errors };
        } else {
          this.$store.dispatch("showSnackbar", {
            msg: "Ошибка создания",
            color: "error",
            show: true,
          });
        }
        this.pending = false;
      }
    },
    async removeEmail(id) {
      try {
        await api.feedbacks.deleteEmail(id);
        this.deleteId = null;
        this.getEmailsList();
      } catch (e) {
        console.error(e);
      }
    },
    async closeDelete() {
      this.dialogDelete = false;
      await this.$nextTick();
      this.deleteId = null;
    },
    async deleteConfirm() {
      if (this.deleteId) this.removeEmail(this.deleteId);
      this.closeDelete();
    },
  },
};
</script>
