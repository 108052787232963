<template>
  <div>
    <v-card>
      <v-tabs>
        <v-tab
          v-for="status in statuses"
          :key="status.name"
          @click="changeTab(status.value)"
          >{{ status.name }}</v-tab
        >
      </v-tabs>
      <v-row class="px-5 pt-4 pb-3" no-gutters>
        <v-col md="5">
          <v-text-field
            label="Поиск имя, email"
            :value="optionsTable.search"
            hide-details
            clearable
            @change="(value) => (optionsTable.search = value)"
            @click:clear="optionsTable.search = ''"
          >
          </v-text-field>
        </v-col>
        <v-col cols="auto" align-self="end">
          <v-btn color="accent" elevation="0" width="40">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="optionsTable"
        :server-items-length="total"
        :loading="loading"
        show-expand
        :footer-props="{ itemsPerPageOptions: [10, 15, 20, 50, 100] }"
      >
        <template v-slot:item.status="{ item }">
          <div class="d-inline-block">
            <v-checkbox
              v-model="item.status"
              class="mt-0"
              :ripple="false"
              hide-details
              @change="changeStatus(item)"
            />
          </div>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-row>
              <v-col lg="7" class="py-10 px-3 px-lg-5">
                {{ item.text }}
              </v-col>
            </v-row>
          </td>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <template>
        <v-card class="pa-1">
          <v-card-title
            class="text-h5 text-center justify-center flex-column mb-5"
          >
            Удалить сообщение<br />
            <span class="accent--text">{{ deleteFeedback.email }}</span>
          </v-card-title>

          <v-card-actions class="pb-4">
            <v-spacer></v-spacer>
            <v-btn color="accent" outlined @click="closeDelete">Отмена</v-btn>
            <v-btn color="accent" @click="deleteItemConfirm">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import api from "../../../api";
import { mapActions } from "vuex";
import { mapGetters } from "vuex";

export default {
  name: "FeedbackTable",
  data() {
    return {
      dialogDelete: false,
      deleteFeedback: {},
      headers: [
        {
          text: "Прочитано",
          align: "left",
          value: "status",
          width: 120,
        },
        {
          text: "Сообщение",
          value: "data-table-expand",
          sortable: false,
          align: "center",
          width: 80,
        },
        {
          text: "Имя",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Email",
          value: "email",
          sortable: true,
          align: "start",
        },
        {
          text: "Телефон",
          value: "phone",
          sortable: false,
          align: "start",
        },
        {
          text: "Действия",
          value: "actions",
          sortable: false,
          align: "end",
          width: 80,
        },
      ],
      items: [],
      optionsTable: {
        filterStatus: "notProcessed",
        search: "",
      },
      total: 0,
      loading: true,
      statuses: [
        {
          value: "notProcessed",
          name: "Новые",
        },
        {
          value: "processed",
          name: "Прочитанные",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      updateTime: "updateTime",
    }),
  },

  watch: {
    optionsTable: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
  },
  mounted() {
    setInterval(() => {
      this.getItems();
    }, this.updateTime);
  },
  methods: {
    ...mapActions({
      getDocsCount: "getDocsCount",
    }),
    async getItems() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage, filterStatus, search } =
        this.optionsTable;
      try {
        const req = await api.feedbacks.list({
          sortBy: sortBy && sortBy.length > 0 ? sortBy[0] : null,
          sortDesc: sortDesc && sortDesc.length > 0 ? sortDesc[0] : null,
          page: page,
          itemsPerPage: itemsPerPage,
          filterStatus: filterStatus,
          search: search,
        });
        if (req.data) {
          this.items = req.data.items;
          this.total = req.data.total;
        }
        this.loading = false;
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },
    changeTab(status) {
      this.optionsTable.filterStatus = status;
      this.optionsTable.search = "";
    },
    async changeStatus(item) {
      const { id, status } = item;
      try {
        await api.feedbacks.status(id, status ? 1 : 0);
        await this.getItems();
        this.$store.dispatch("getFeedbacksCount");
      } catch (e) {
        console.error(e);
      }
    },
    deleteItem(item) {
      this.deleteFeedback = item;
      this.dialogDelete = true;
    },
    async closeDelete() {
      this.dialogDelete = false;
      await this.$nextTick();
      this.deleteFeedback = {};
    },
    async deleteItemConfirm() {
      try {
        await api.feedbacks.deleteItem(this.deleteFeedback.id);
        this.getItems();
        this.closeDelete();
      } catch (e) {
        console.error(e);
        this.$store.dispatch("showSnackbar", {
          msg: "Ошибка удаления",
          color: "error",
          show: true,
        });
        this.closeDelete();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
